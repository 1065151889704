<template>
  <modal name="modalDashboard" height="auto" width="94%" :lazy="true" :scrollable="true" @before-open="validation.reset()" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs>
          <CTab :title="modalTitle">
            <div class="form">
              <div class="alert remover" v-show="!!errorMessage">
                <a href="#" class="close" @click="errorMessage = null">&times;</a>
                Falha ao salvar:
                <p>{{ errorMessage }}</p>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label>Titulo</label>
                    <input class="form-control" v-model="dashboard.titulo" maxlength="255"/>
                    <div class="message">{{ validation.firstError('dashboard.titulo') }}</div>
                  </div>
                </div>
                <div class="col-2-3">
                  <div class="form-group">
                    <label>Descrição</label>
                    <input class="form-control" v-model="dashboard.descricao" maxlength="255"/>
                    <div class="message">{{ validation.firstError('dashboard.descricao') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-1">
                  <div class="form-group">
                    <label>Sistemas *</label> <span class="message" v-if="validation.firstError('dashboard.sistemas')">{{ validation.firstError('dashboard.sistemas') }}</span>
                    <div v-for="sistema in sistemas">
                      <label class="checkbox">
                        <input type="checkbox" :value="sistema.id" v-bind:checked="sistemaChecked(sistema.id)" v-on:change="handleSistemaChecked(sistema.id)">
                        {{ sistema.descricao}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-1">
                  <div class="form-group">
                    <label>Perfis *</label> <span class="message" v-if="validation.firstError('dashboards.perfis')">{{ validation.firstError('dashboard.perfis') }}</span>
                    <div v-for="tipo in tiposPerfis">
                      <label class="checkbox">
                        <input type="checkbox" :value="tipo.id" v-bind:checked="perfilChecked(tipo.id)" v-on:change="handlePerfilChecked(tipo.id)">
                        {{ tipo.descricao}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-3-4">
                  <div class="form-group">
                    <label>Layout</label>
                    <div v-bind:class="{ 'layout-container': true, 'no-itens': dashboard.widgets.length === 0 }">
                      <grid-layout
                          :layout.sync="dashboard.widgets"
                          :col-num="12"
                          :row-height="30"
                          :is-draggable="true"
                          :is-resizable="true"
                          :is-mirrored="false"
                          :vertical-compact="true"
                          :margin="[10, 10]"
                          :use-css-transforms="true"
                      >
                        <grid-item v-for="item in dashboard.widgets"
                                   :x="item.x"
                                   :y="item.y"
                                   :w="item.w"
                                   :h="item.h"
                                   :i="item.i"
                                   :key="item.i">
                          <div class="layout-widget-container">
                            <h6 class="layout-widget__title">
                              {{item.titulo}}
                            </h6>
                            <div class="layout-widget__close" v-on:click="handleWidgetChecked(item)">
                              &times;
                            </div>
                          </div>
                        </grid-item>
                      </grid-layout>
                    </div>
                  </div>
                </div>
                <div class="col-1-4" v-on:click="">
                  <div class="form-group">
                    <label>Selecionar Widgets</label>
                    <div v-if="!widgetsPermitidos.length">
                      Nenhum widget disponível para os perfis selecionados
                    </div>
                    <div class="widget-container" v-for="widget in widgetsPermitidos">
                      <label class="checkbox">
                        <input type="checkbox" :value="widget.id" v-bind:checked="widgetChecked(widget.id)" v-on:change="handleWidgetChecked(widget)">
                        {{ widget.titulo}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" :class="{ 'button button-success': true, 'button-disabled': false }" v-on:click="handleSubmit()">
        Salvar
      </button>
      <button type="button" class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import { find, filter, map, some } from "lodash";
import { toggleObjByIndex, toggleValue } from "../../../../helpers/utils";
import VueGridLayout from "vue-grid-layout";
import CloseModal from "../../../../components/CloseModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "ModalDashboard",
  props: ["dashboard", "widgets", "sistemas", "tiposPerfis", "save"],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    CloseModal,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  computed: {
    modalTitle() {
      return this.dashboard.id ? "Editar Dashboard" : "Nova Dashboard";
    },
    sistemasSelecionados() {
      return filter(this.sistemas, (sistema) => {
        return !!find(
          this.dashboard.sistemas,
          (sistemaId) => `${sistemaId}` === `${sistema.id}`
        );
      });
    },
    perfisSelecionados() {
      return filter(this.tiposPerfis, (tipo) => {
        return !!find(this.dashboard.perfis, (dP) => `${dP}` === `${tipo.id}`);
      });
    },
    widgetsPermitidos() {
      return filter(this.widgets, (w) => {
        return find(this.dashboard.perfis, (dP) => {
          return some(w.perfis, (wP) => {
            return `${dP}` === `${wP}`;
          });
        });
      });
    },
  },
  watch: {
    "dashboard.perfis": function() {
      this.dashboard.widgets = filter(this.dashboard.widgets, (dP) => {
        return find(this.widgetsPermitidos, { id: dP.id });
      });
    },
  },
  validators: {
    "dashboard.titulo": (value) => Validator.value(value).required(),
    "dashboard.descricao": (value) => Validator.value(value).required(),
    "dashboard.sistemas": (value) => Validator.value(value).required(),
    "dashboard.perfis": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.save(this.dashboard);
        }
      });
    },
    sistemaChecked(id) {
      return !!find(this.sistemasSelecionados, { id: id });
    },
    handleSistemaChecked(id) {
      this.dashboard.sistemas = toggleValue(this.dashboard.sistemas, id);
    },
    perfilChecked(id) {
      return !!find(this.perfisSelecionados, { id: id });
    },
    handlePerfilChecked(id) {
      this.dashboard.perfis = toggleValue(this.dashboard.perfis, id);
    },
    widgetChecked(id) {
      return !!find(this.dashboard.widgets, { id: id });
    },
    handleWidgetChecked(widget) {
      this.dashboard.widgets = toggleObjByIndex(
        this.dashboard.widgets,
        widget,
        "id"
      );
    },
    close() {
      this.$modal.hide("modalDashboard");
    },
  },
};
</script>

<style scoped>
.layout-container {
  min-height: 500px;
  background-color: #fafafa;
  border: 1px dashed #ccc;
  border-radius: 4px;
}
.layout-container.no-itens:before {
  content: "Selecione os widgets ao lado";
  font-size: 26px;
  display: block;
  position: absolute;
  width: 100%;
  vertical-align: middle;
  margin-top: 100px;
  text-align: center;
  box-sizing: border-box;
  color: #ccc;
}
.layout-widget-container {
  background-color: #fff;
  height: 100%;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.7);
  border-top: 4px solid #004576;
}
.layout-widget__title {
  color: #004576;
  font-size: 14px;
}
.layout-widget__close {
  color: #004576;
  font-size: 16px;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
}
.widget-container {
  width: 100%;
  clear: both;
}
</style>
